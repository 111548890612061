const Warning = () => {
    return (
        <>
        <div className="warning text-center">
        <h1>Satta king | Sattaking | Satta result | Satta king live online result</h1>




        Satta King(सट्टा किंग), you probably heard this name commonly. This name is exceptionally well known in India<a href="/">Satta king</a>.. Satta King(सट्टा किंग) is a sort of lottery game in light of numbers from
        00 to 99 which goes under "Betting". The genuine name of this game is Satta Matka, In which "Satta" signifies
        wagering or betting and "Matka" signifies a pot through which a number is really long. In the Satta Matka game,
        individuals wage cash on their picked numbers from between 00 to 99. After which, a number is really long of the
        pot. Whichever individual's number was really long, He would win the award and individuals called him as the
        Satta lord. Satta King(सट्टा किंग) isn't the situation, it was the title used to respect the champ of the Satta
        Matka<a href="/">Satta king</a>. In any case, as this game became well known, individuals began knowing
        it by the name of Sattaking. Satta ruler isn't the situation, it is just called Satta King(सट्टा किंग) to
        respect the individual who won the Satta. However, as individuals began playing increasingly more satta, the
        victor of the Satta was given a title that he would be known as "Satta Lord" and simultaneously individuals
        began calling this game as Sattaking. Satta lord resembles a sort of infection. It is played in huge amounts in
        the entire nation or in different dialects, say that the wagering has gravely raised a ruckus around town
        country<a href="/">Sattaking</a>. Do you have any idea about that under Indian regulation it against the
        law against the law to play wagering? Yet individuals who play Satta are hiden from the organization or police.
        Indeed, even regardless of severe standards of organization, individuals attempt to play Satta lord on the web.
        In any case, the ubiquity of playing SattaKing in India is expanding step by step. Certain individuals play
        Satta game disconnected, certain individuals play Satta game utilizing the Web. He needs to get rich as fast as
        conceivable in a brief time frame<a href="/">Satta matka</a>. To play Satta or whether you need to
        acquire information about Satta lord, you should peruse this article becouse I will give you complete data
        pretty much a wide range of satta, benefits or burdens connected with Sattaking.


        <h3><b>History of Satta king(सट्टा किंग)</b></h3>
        Satta ruler was initially begun in the US of America, which initially elaborate wagering on the opening and
        shutting paces of cotton sent from the New York Cotton Trade. During the 1960s, the New York Cotton Trade halted
        the training, after which the bookies began one more method for drawing irregular number.
        The credit for beginning the Sattaking in India goes to 2 major bookies Kalyanji Bhagat and Ratan Khatri. In
        India, the Satta ruler was initially begun from Mumbai<br/>
        In 1961, getting an irregular number from the pot was begun by a major bettor named Ratan Khatri. As per his
        thought, 1 to 100 numbers were composed on slips and were placed in a pot. Then without seeing a number was
        drawn from the pot.<br/>
        In 1962, a bookie named Kalyanji Bhagat began the Warli Matka game, After that Ratan Khatri began the new Worli
        game in 1964 for certain new principles. Kayanji Bhagat's Worli Matka Game, used to run the entire day in the
        week, Yet Ratan Khatri's Matka Game, used to run exclusively for 5 days in the week.<br/>
        During the 1980s to 1990s, the Satta ruler turned out to be famous to such an extent that its month to month
        wagering volume had developed to in excess of 500 crore. Continuously, the Sattaking became famous in different
        provinces of India as well and enormous number of individuals began playing this game.
        Kalyanji Bhagat, Suresh Bhagat and Ratan Khatri are three such names who have brought in a ton of cash through
        Satta lord and they are known as Matka Ruler<br/>
        <h3><b>How to play satta king ?</b></h3>
        In Satta Lord, individuals bet wagers on their picked numbers somewhere in the range of 0 and 99. For this the
        bettors contact their region's Khaiwal. Khaiwal fills in as a middle person/center man between the bettors and
        the game administrators. Each Khaiwal gathers cash and the number from the players of his area and sends it to
        the organization. Furthermore, when a champ is proclaimed he gathers the rewards from the organization and
        conveys it to the triumphant bettor.
        At a predefined time the Satta king(सट्टा किंग) organization opens an irregular number(Satta result). The
        triumphant bettor gets multiple times the cash he bet on a wining number.<br/>
        <h3><b>Why you should play satta king online and how ?</b></h3>

        Why you ought to play Satta Ruler on the web? In the event that we discuss this, the response will be that you
        ought to play Satta ruler online on the grounds that it is not difficult to play Sattaking(सट्टा किंग) in the
        Web world.<a href="/">Sattaking</a> To stay away from the organization and play Satta ruler then the
        Web world might be a decent choice for you. <br/>Since getting an examiner in the web world is difficult. Yet,
        let us let you know that this game is unlawful.<br/>
        To play wagering, then you will find numerous such applications on Google Play Store or on Apple Store, through
        which you can play Satta lord by putting away cash on the web.
        <h3><b>What are the different types of Satta King game ?</b></h3>
        There are mainly four types of Satta king game played in India, <a href="/">Desawar Satta king</a>, <a href="/">Gali Satta king</a>, <a href="/">Gaziyabad Satta king</a> and <a href="/">Faridabad Satta king</a>. Except of these four games many khaiwal started their own game
        independently like Rajkot, Taj, New Faridabad, Hidustan, Peshawar, etc.<br/>
        <h3><b>Where to get Satta result ?</b></h3>


        In the present web world, Getting a <a href="/">Satta Result</a> exceptionally quick not no joking
        matter. You will
        find numerous sites on web which gives live satta result on their entrance. On these site you will get
        quickest gali result, disawar result, faridabad result, gaziyabad result, and so on.
        All you need to do simply look satta result, gali result, disawar result, faridabad result, gaziyabad result on
        google and you will get live satta result quickly online.<br/>
        <h3><b>How to find a perfact winning number?</b></h3>To bring in cash from Satta ruler game all you really want
        is a
        winning number. There isn't numerical recipe to draw this number. Individuals simply surmise the following
        number by
        taking a gander at the old <a href="/">Satta lord record chart</a> of any game. Subsequently, they play
        many numbers
        simultaneously.

        You will find many Bookies who according to their calculations sell their predicted numbers(Satta result) to the
        players. They charge a big fee for this. But it is not necessary that their number will be the winning number.
        They also guess based on their experience and their guesses(Satta result) are many times correct as well.
        <br/>
        <h3><b>How much profit you can earn from satta king ?</b></h3>Suppose if someone has bet 10 rupees on a number,
        then if that number is opened then the user will get 10 x 90 = 900 Rupees. Similarly, users will get 1800 rupees
        for 20 rupees, 2700 rupees for 30 rupees, 3600 rupees for 40 rupees and 4500 rupees for 50 rupees. The user can
        invest as much money as he wants on one number and can play many numbers as he wants.<br/>
        <h3><b>What happen when you play Satta king ?</b></h3>
        A great many people have the misinterpretation that assuming that they play Satta king(सट्टा किंग), they will
        win and procure great sum
        of cash. In any case, this happens a remarkable inverse. Those individuals get caught in such game so seriously
        that they loses
        all that and get ruined.<br/><br/>
        As you most likely are aware, just a single number(Satta result) from 00 to 99 opens in this game. This actually
        intends that in this game, out
        of 100 individuals only one man gets a lottery. The leftover 99 individuals just loses in this game.
        Furthermore, the entire cash
        of 99 failures is given to the champ. The possibilities dominating in this match are 1% out of 100. Individuals
        know about
        this yet they need to play this game till they are totally destroyed.
        <br/>
        <h3><b>What is the Reality of Satta king game ?</b></h3>
        Satta king(सट्टा किंग) is a type of lottery game. In which 100 people take part on their free will. Out of which
        one person is chosen as winner between 1 to 100 numbers and for this, all 100 numbers are put in a pot. Then a
        slip of any number is drawn. The person who has the number on the slip will be the winner.
        Actually, this is how people described this game but nothing like this happens in this game. In fact in this
        game, <a href="/">Satta king Company</a> does not pick the slip of any one number by putting the
        numbers in the pot, but only the number which has less money invested on it is drawn out of 100 numbers. Because
        the Sattaking company runs to business to make the most profit for themselves. No number is randomly opened. The
        number is fixed by the Satta king company.
        Which means that the team of Sattaking gets a lot of profit, which means who will be the winner is depends on
        the team of Satta King.<br/><br/>
        <h3><b>Disclaimer</b></h3>
        Satta-lord matka.in is a non business site. Seeing This Site Is Your Own Gamble. All The Data
        Displayed On Site Is Supported And We Caution You That Matka Betting/Satta lord Might Be Prohibited Or Unlawful
        In Your
        Country. We Run No Satta Matka Betting And Have No Association With Satta Makta/Satta Lord
        Organization In any capacity. On the off chance that Somebody Request that You Pay Cash Kindly Accept Choice
        according to your comprehension. We will be
        Not Answerable For Any Issues Or Trick. We Regard All Nation Rules/Regulations. In the event that You Not Concur
        With Our Site
        Disclaimer. Kindly Quit Our Site At the present time. Much obliged
    </div>
        </>
    )
}

export default Warning